<template>
  <div id="pdfsCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <EditPdfDialog
      :superLoading="superLoading"
      :selectedPdf="selectedPdf"
      :pdfDialog="pdfDialog"
      @setSuperLoading="setLoading"
      @closePdfDialog="closePdfDialog"
      @deletePdf="deletePdf"
      @savePdf="savePdf"
    />

    <v-card :loading="loading || superLoading" class="w-100 pb-4 px-4">
      <!-- Title -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1">mdi-chevron-right</v-icon> Edit PDFs:
      </h6>

      <!-- Add Pdf Form -->
      <v-form class="mt-3" v-model="valid" @submit.prevent ref="pdfGroupForm">
        <v-container>
          <v-row align="center" no-gutters v-if="error">
            <v-col cols="12">
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <!-- Input Row w/ Button -->
            <v-col cols="12" sm="10" class="mx-auto">
              <div>
                <v-text-field
                  color="deep-purple accent-4"
                  v-model="pdfGroupName"
                  type="text"
                  :rules="pdfGroupNameRules"
                  label="PDF Group Name"
                  placeholder="Class 9 P-C-M"
                  class="mx-1"
                  :disabled="loading || superLoading"
                  prepend-icon="mdi-file-pdf-box"
                  autofocus
                  outlined
                  dense
                  required
                ></v-text-field>
              </div>
              <v-btn
                color="pink darken-2 white--text"
                class="float-right"
                :disabled="loading || superLoading || !valid"
                raised
                @click="addPdfGroup"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>Add
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-1 mt-5"></v-divider>
        </v-container>
      </v-form>

      <!-- Display Pdfs List -->
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7">
            <template v-for="(pdf, i) in pdfs">
              <v-list-item :key="pdf.name">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip> {{ i + 1 }}. {{ pdf.name }} </v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <!-- Manage PDFs -->
                <v-list-item-action>
                  <v-btn
                    :disabled="loading || superLoading"
                    :to="'/manage/pdfs/' + i"
                  >
                    <v-icon class="mr-2" color="pink darken-1">
                      mdi-18px mdi-file-multiple</v-icon
                    >
                    Manage PDFs
                  </v-btn>
                </v-list-item-action>

                <!-- Edit Button -->
                <v-list-item-action>
                  <v-btn
                    :disabled="loading || superLoading"
                    @click="editPdfModal(i)"
                  >
                    <v-icon class="mr-2" color="pink darken-1">
                      mdi-18px mdi-notebook-edit</v-icon
                    >
                    Edit
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="pdf.name + '_divider'"></v-divider>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import EditPdfDialog from "@/components/Home/Manage/Pdfs/EditPdfDialog.vue";

export default {
  name: "PdfsCard",
  props: ["superLoading"],
  components: { EditPdfDialog },
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    pdfDialog: false,
    deletePdfDialog: false,
    selectedPdf: {},
    valid: false,
    addTestGroupValid: false,
    pdfGroupName: "",
    pdfFile: null,
    pdfDesc: "",
    pdfGroupNameRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length > 1 || "Field length should be greater than 1 characters",
      (value) =>
        value.length < 500 || "Field length should be less than 500 characters",
    ],
    pdfFileRules: [
      (value) => !!value || "Required.",
      (value) =>
        !value ||
        value.size < 5000000 ||
        "Pdf logo image should be less than 5MB!",
    ],
    pdfs: {},
    testGroup: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    closePdfDialog() {
      this.setLoading(true);
      this.pdfDialog = false;
      this.selectedPdf = null;

      //to reset automatic (bug) changes to vuex state
      window.location.reload();
    },

    doesPdfGroupExist(pdfGroupName) {
      var existsAlready = false;

      this.pdfs.some((pdfObj) => {
        if (pdfObj.name == pdfGroupName) {
          existsAlready = true;
          return true;
        }
      });

      return existsAlready;
    },

    addPdfGroup() {
      //write locally
      this.error = "";
      if (this.doesPdfGroupExist(this.pdfGroupName.toLowerCase())) {
        this.error = "This pdf group already exists :(";
        return;
      }

      const pdfObj = {
        name: this.pdfGroupName.toLowerCase(),
        folders: ["demo folder"],
      };

      this.pdfs.push(pdfObj);

      //write to server

      this.$store
        .dispatch("savePdfGroups", this.pdfs)
        .then(() => {
          this.snackbarText = "Pdf added successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.pdfGroupName = "";
          this.pdfFile = null;
          this.snackbar = true;
          this.closePdfDialog();
        });
    },

    editPdfModal(index) {
      this.selectedPdf = this.pdfs[index];
      this.$set(this.selectedPdf, "arrayPos", index);
      this.pdfDialog = true;
      //reset pdfs to lose data
    },

    deletePdf() {
      this.setLoading(true);
      this.error = "";
      //remove from pdfs array
      this.pdfs.splice(this.selectedPdf.arrayPos, 1);

      //write to server
      this.$store
        .dispatch("savePdfGroups", this.pdfs)
        .then(() => {
          this.snackbarText = "Pdf deleted successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
          this.closePdfDialog();
        });

      this.deletePdfDialog = false;
    },

    savePdf(localPdfObj) {
      const pdfObj = localPdfObj;
      delete pdfObj.arrayPos;

      //add to local pdfs array
      this.$set(this.pdfs, localPdfObj.arrayPos, pdfObj);

      //write to server
      this.$store
        .dispatch("savePdfGroups", this.pdfs)
        .then(() => {
          this.snackbarText = "Pdf edited successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
          this.closePdfDialog();
        });
    },

    fetchPdfs() {
      // get pdfs from store
      const pdfs = this.$store.getters.pdfGroups;

      //if not found in store
      if (pdfs.length == 0) {
        //get folder names from server
        this.$store
          .dispatch("getPdfDoc")
          .then((res) => {
            if (res) {
              this.pdfs = res.pdfGroups;
            }
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.pdfs = pdfs;
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.fetchPdfs();
  },
};
</script>
