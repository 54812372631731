<template>
  <div id="editPdfDialog">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Delete dialog -->
    <v-dialog v-model="deletePdfDialog" persistent max-width="590">
      <v-card v-if="localPdf" :loading="loading || superLoading">
        <v-card-title class="headline">
          Are you sure you want to delete this PDF group ?
        </v-card-title>
        <v-card-subtitle class="mt-1">
          <b>Pdf:</b> {{ localPdf.name }}
        </v-card-subtitle>
        <v-card-text>
          This action cannot be reversed. Please click the "DELETE" button to
          confirm.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            dark
            :loading="loading || superLoading"
            @click="deletePdf()"
          >
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon> Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading || superLoading"
            @click="deletePdfDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Pdf dialog -->
    <v-dialog max-width="800" v-model="pdfDialog" persistent>
      <v-card tile v-if="localPdf">
        <!-- Toolbar -->
        <v-toolbar dense dark color="pink darken-1">
          <v-btn icon dark @click="closePdfDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="hidden-sm-and-down"
            >Edit PDF Group</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- Delete Modal Button -->
            <v-btn @click="deletePdfDialog = true" dark text class="mr-1" small>
              <v-icon class="mr-1">mdi-trash-can-outline mdi-18px</v-icon>
              Delete
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Error Row -->
        <div v-if="error" class="mt-5 mb-n3 px-1 px-md-3">
          <v-alert type="error">{{ error }}</v-alert>
        </div>

        <!-- Pdf Name -->
        <v-card-subtitle class="mt-5 mt-md-9">
          <v-chip class="mx-1">
            <b class="mr-1">{{ localPdf.name }}</b> Folders :
          </v-chip>
        </v-card-subtitle>

        <v-card-text>
          <v-container class="pa-2" fluid>
            <!-- Test Groups List -->
            <template v-for="(folder, i) in localPdf.folders">
              <v-list-item :key="folder + '_list-item'">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip> {{ i + 1 }}. {{ folder }} </v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <!-- Delete Button -->
                <v-list-item-action>
                  <v-btn icon @click="deleteFolder(i)">
                    <v-icon color="red darken-1">
                      mdi-18px mdi-trash-can
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="folder + '_divider'"></v-divider>
            </template>

            <!-- Add Test Group Form -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-form
                    class="mt-3 d-flex"
                    v-model="addFolderValid"
                    @submit.prevent="addFolder"
                    ref="folderForm"
                  >
                    <v-text-field
                      color="pink accent-4"
                      v-model="folder"
                      type="text"
                      :rules="folderRules"
                      label="Test Group Name"
                      placeholder="Class 9 P-C-M"
                      class="mx-1"
                      :disabled="loading || superLoading"
                      outlined
                      dense
                      required
                    ></v-text-field>
                    <!-- Add Test Group Button-->
                    <v-btn
                      @click="addFolder"
                      :disabled="loading || superLoading || !addFolderValid"
                      icon
                    >
                      <v-icon color="red darken-1"> mdi-18px mdi-plus </v-icon>
                    </v-btn>
                  </v-form>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="savePdf()"
            color="pink darken-1 white--text"
            :disabled="loading || superLoading || localPdf.folders.length < 1"
          >
            <v-icon class="mr-1">mdi-content-save mdi-18px</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditPdfDialog",
  props: ["superLoading", "selectedPdf", "pdfDialog"],
  watch: {
    //copy selectedPdf to local data when dialog opens
    pdfDialog: function (newVal) {
      if (newVal) {
        //if pdf dialog opened
        this.localPdf = this.selectedPdf;
      } else {
        //if pdf dialog closed
        this.localPdf = null;
      }
    },
  },

  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    deletePdfDialog: false,

    //form validation
    addFolderValid: false,
    folderRules: [
      (value) => !!value || "Required.",
      (value) =>
        value.length > 1 || "Field length should be greater than 1 characters",
      (value) =>
        value.length < 500 || "Field length should be less than 500 characters",
    ],

    //form values
    folder: "",
    pdfDesc: "",
    localPdf: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    deleteFolder(index) {
      //remove from folders array
      this.localPdf.folders.splice(index, 1);
    },

    addFolder() {
      var folderArr = this.localPdf.folders;

      if (folderArr.includes(this.folder)) {
        this.snackbarText = "Test Group already exists :(";
        this.snackbar = true;
        return;
      }

      folderArr.push(this.folder);
      this.$set(this.localPdf, "folders", folderArr);
      this.folder = "";
    },

    deletePdf() {
      this.$emit("deletePdf");
    },

    closePdfDialog() {
      this.$emit("closePdfDialog");
    },

    savePdf() {
      this.$emit("savePdf", this.localPdf);
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
